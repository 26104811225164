export default [
  {
    title: "Produtos",
    icon: "StarIcon",
    children: [
      {
        title: "Categorías",
        route: "products-categories",
      },
      {
        title: "Lista",
        route: "products",
      },
    ],
  },
  {
    title: "Usuarios",
    icon: "UsersIcon",
    children: [
      {
        title: "Estudiantes",
        route: "users-students",
      },
      {
        title: "Representantes",
        route: "users-representatives",
      },
      {
        title: "Profesores",
        route: "users-teachers",
      },
    ],
  },
  {
    title: "Colegios",
    route: "schools",
    icon: "AwardIcon",
  },
  {
    title: "Transacciones",
    route: "transactions",
    icon: "BarChart2Icon",
  },
  /* {
    title: "Menu",
    route: "menu",
    icon: "MenuIcon",
  }, */
  {
    title: "Tienda",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Tienda",
        route: "apps-e-commerce-shop",
      },
      {
        title: "Cajero Automático",
        route: "apps-e-commerce-shop-public",
      },
    ],
  },
];
