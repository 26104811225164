export default [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Produtos",
    icon: "StarIcon",
    children: [
      {
        title: "Categorías",
        route: "products-categories",
      },
      {
        title: "Lista",
        route: "products",
      },
    ],
  },
  {
    title: "Usuarios",
    icon: "UsersIcon",
    children: [
      {
        title: "Estudiantes",
        route: "users-students",
      },
      {
        title: "Representantes",
        route: "users-representatives",
      },
      {
        title: "Profesores",
        route: "users-teachers",
      },
      {
        title: "Cajeros",
        route: "users-cashiers",
      },
      {
        title: "Administradores",
        route: "users-admins",
      },
    ],
  },
  {
    title: "Roles permisos",
    route: "roles-permissions",
    icon: "LockIcon",
  },
  {
    title: "Colegios",
    route: "schools",
    icon: "AwardIcon",
  },
  {
    title: "Transacciones",
    route: "transactions",
    icon: "BarChart2Icon",
  },
  {
    title: "Tienda",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Tienda",
        route: "apps-e-commerce-shop",
      },
      {
        title: "Cajero Automático",
        route: "apps-e-commerce-shop-public",
      }
    ],
  },
  /* {
    title: "Menu",
    route: "menu",
    icon: "MenuIcon",
  }, */
  {
    title: "Bancos",
    route: "banks",
    icon: "ShoppingBagIcon",
  },
  {
    title: "Notificaciones",
    route: "notifications",
    icon: "BellIcon",
  },
  {
    title: "Bot whatsapp",
    route: "bot-WhatsApp",
    icon: "TagIcon",
  },
];
